exports.components = {
  "component---src-pages-affiliate-program-jsx": () => import("./../../../src/pages/affiliate_program.jsx" /* webpackChunkName: "component---src-pages-affiliate-program-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie_policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-data-jsx": () => import("./../../../src/pages/our_data.jsx" /* webpackChunkName: "component---src-pages-our-data-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy_policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-condition-jsx": () => import("./../../../src/pages/terms_condition.jsx" /* webpackChunkName: "component---src-pages-terms-condition-jsx" */),
  "component---src-templates-influencer-listing-index-jsx": () => import("./../../../src/templates/InfluencerListing/index.jsx" /* webpackChunkName: "component---src-templates-influencer-listing-index-jsx" */)
}

